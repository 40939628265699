@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }

  #about,
  #services,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }

  #menu a.navbar-brand {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  #about img {
    margin: 50px 0;
  }

  header {
    display: none;
  }

  #menu .lang_switch_btn {
    display: none;
  }

  #team .about-text li {
    text-align: left;
  }

  #footer img {
    height: 20px;
    margin-right: 4px;
  }

  #footer .footer-name {
    font-size: 12px;
  }

  #footer .footer-link {
    font-size: 12px;
  }
}

@media screen and (max-width: 992px){
  #about h2::after {
    left: 50%;
    transform: translateX(-50%);
  }
  #about .about-text .about-title {
    text-align: center;
  }
}